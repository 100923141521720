.nav{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.navIcon {
    width: 7em;
}

@media only screen and (min-width: 421px){
    .nav{
    justify-content: start;
}

}
/* @media only screen and (min-width: 834px){
    .nav{
    background-color: black;
}} */