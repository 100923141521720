.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5em;
    text-align: center;
}
.header .video {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; 
    position: relative;

}
.header .video video{
    width: 100%;
    border-radius: 15px;
}
.header .video .controls{
    width: 1em;
    padding: .5em;
    border-radius: 50%;
    background-color: var(--bg-dark-30);
    margin:.5em;
    position: absolute;
}
.header .video .controls.opaque {
    opacity: .3;
}
.header h6{
    font-weight: bold;
}

.header .info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2em;
}
.header .media{
    width: 90%;
}


.header a{
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 5px;
    color: var(--text-beige)!important;
    background-color: var(--bg-dark-grey);
    text-transform: capitalize;
}
 @media only screen and (min-width: 700px){
    .header .info{
        width: 90%;
    }
}
@media only screen and (min-width: 1080px){
    
    .header{
        height: 90vh;
    }
    .header .info{
        width: 90%;
        flex-direction: row;
    }

    .header .video video{
        width: 600px;
        height: 500px;
    }
    .header .video .controls{
        margin: 1em;
        margin-top: 2em;
    }
    .header .media{
        width: 40%;
    }
    .header .description{
        width: 40%;
    }
}