.features {
    width: 100%;
}
.feature {
    user-select: text;
    background-color: var(--bg-beige);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: .5em auto;
    padding: .5em;
    min-height: 18em;
    max-width: 1280px;
    text-align: center;
}

.feature .image {
    width: 100%;
}

.feature .image img{
    width: 15em;
}

/* .feature p {} */

@media only screen and (min-width: 700px) {
    .feature {
        flex-direction: row;
        justify-content: space-evenly;
        text-align: left;
    }
    .feature p{
        width: 40%;
    }
    html[lang="ar"] .feature {
        text-align: right;
    }

    .feature .image {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 50%;
    }

}

/* @media only screen and (min-width: 1080px) {

    .features {
        height: 26em;
        overflow-y: scroll;
    }

    .feature {
        height: 26em;
    }
} */