.quote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5em;
    margin: 5em auto;
    text-align: center;
}

.quote h6{
    font-weight: bold;
    width: fit-content;
}
/* .quote p{
} */
/* @media only screen and (min-width: 421px){


} */
@media only screen and (min-width: 834px){
    .quote{   
        align-items: initial;
        text-align: left;
}

}