.footer {
    color: var(--text-beige) !important;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    background-color: var(--bg-dark-grey);
    justify-content: space-around;
    padding: .5em 0;
}

.footer p,
a {
    color: var(--text-beige) !important;
    justify-self: center;

}

.footer p {
    grid-area: 2 / 1 / 2 / 4;
    text-align: center;
}

.footer .social_media {
    font-weight: bold;
    width: fit-content;
}

.footer .languages {
    position:relative;
    width: fit-content;
    background-color: transparent;
    text-transform: capitalize;
    gap: .5em;
    flex-direction: column-reverse;
    justify-self: center;
}

.footer .language {
    font-family: 'Titillium Web';
    cursor: pointer;
    width: 1.6em;
    height: 1.6em;
    color: #FAFAFA;
    background-color: var(--bg-dark);
}
.footer .language:hover{
    opacity: .8;
}
.footer .language_options {
    position: absolute;
    bottom: 2.5em;
    gap: .5em;
}

.footer .options_visible {
    position: absolute;
    bottom: 2.5em;
}

/* .header p{
} */
/* @media only screen and (min-width: 421px){
   

} */
@media only screen and (min-width: 834px) {

    .footer {
        display: flex;
        justify-content: space-around;
        text-align: center;
    }

}