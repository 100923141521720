body {
    background-color: #FAFAFA;
    font-family: "Titillium Web", sans-serif;

    min-width: 360px;
    max-width: 1920px;
    --text-dark-grey: #474747;
    --text-grey: #616161;
    --text-beige: #E0E0E0;

    --bg-dark-grey: #7A7A7A;
    --bg-dark: #3B3B3B;
    --bg-dark-30: #3B3B3B60;

    --bg-beige: #E0E0E0;
    user-select: text;
}

html[lang="ar"] body {
    font-family: "Readex Pro", sans-serif !important;

}

h6 {
    color: var(--text-black);
    font-family: "Titillium Web", sans-serif !important;

}

html[lang="ar"] h6 {
    font-family: "Readex Pro", sans-serif !important;
}

p {
    font-family: "Titillium Web", sans-serif !important;
    color: var(--text-dark-grey);
}

html[lang="ar"] p {
    font-family: "Readex Pro", sans-serif !important;
}

a {
    font-weight: bold !important;
    text-decoration: none !important;
    cursor: pointer;
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

html[lang="ar"] .app {
    direction: rtl;
}

@media only screen and (min-width: 421px) {
    body {
        margin: 0 1em;
    }

}

@media only screen and (min-width: 834px) {
    body {
        margin: 0 2em;
    }

}

/* @font-face {
    font-family: "Titillium Web";
    src:
      local("Titillium Web"),
      url("/src/assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf") format("TrueType")
  }


  @font-face {
    font-family: "Readex Pro";
    src:
      local("Readex Pro"),
      /* url("/src/assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf") format("TrueType") 
  } */